const config = {
  login: 'https://backend.sadekdev.tech/api/login',
  dashboard: 'https://backend.sadekdev.tech/api/orders',
  register: 'https://backend.sadekdev.tech/api/register',
  logout: 'https://backend.sadekdev.tech/api/logout',
  sendRequest: 'https://backend.sadekdev.tech/api/orders'

};

export default config;
